/*============================================================
  このプロジェクト用 Javascript

  2020/05/13  (Ver1.00) dpc7版(mdc.coffee)を Javascript に変換して新規作成
  2020/12/24  (Ver1.02) 更新
============================================================ */



//------------------------------------------------------------
/*-- 「処理中」オーバーレイの表示実行（class="show_processing" が対象） --*/
//------------------------------------------------------------
var processing_show;
processing_show = function() {
  $.blockUI({
    message: 'Now Loading',
    fadeIn: 10,
    fadeOut: 10,
    css: {
      border: 'none',
      padding: '10px',
      backgroundColor: '#333',
      opacity: .5,
      color: '#fff'
    },
    overlayCSS: {
      backgroundColor: '#fff',
      opacity: 0
    }
  });
};

//------------------------------------------------------------
/*-- 現在時刻の取得 --*/
//------------------------------------------------------------
var get_time;
get_time = function() {
  var d, date, hour, min, month, sec, str, year;
  d = new Date;
  year = d.getFullYear();
  month = d.getMonth() + 1;
  date = d.getDate();
  hour = d.getHours();
  min = d.getMinutes();
  sec = d.getSeconds();
  str = year + "年" + month + "月" + date + "日";
  str += " " + hour + "時" + min + "分" + sec + "秒";
  return str;
};

//------------------------------------------------------------
//  「処理中」のオーバーレイ表示を消す コントロール
//------------------------------------------------------------
var show_processing;
show_processing = function(mode, data) {
  if (data = null) {
    data = '';
  }
  if (mode === 'start') {
    return setTimeout($.unblockUI, 1);
  } else if (mode === 'done') {
    return setTimeout($.unblockUI, 1);
  } else if (mode === 'fail') {
    alert("バックグラウンドの処理（ajax）が失敗しました。" + (get_time()) + data);
    return setTimeout($.unblockUI, 1);
  } else {
    return setTimeout($.unblockUI, 1);
  }
};

// document.addEventListener('turbolinks:load', () => {
//
//     //ここにjsの記述を追加
//
// })



// -----------------------------------------------------------
//  「表示内容の切り替え」ボタンのクリック検出と ajax 通信  （2020/05/29）
// -----------------------------------------------------------
$(function() {
  $(document).on('click', '.contents-change', function() {
    var button_val    = this.value;               //  ボタンの value 値
    var data_id       = $(this).data('id');       //  ボタンの id 値
    var sort_by       = $(this).data('sort-by');
    var link_para     = $(this).data('link_para');
    var url_path      = gon.url_path;

    processing_show();
    $.ajax({
      url: url_path,
      type: 'GET',
      data: {
        url: url_path,
        data_id: data_id,
        sort_by: sort_by,
        link_para: link_para,
        url_params: gon.url_params      }
    }).fail(function() {
      return show_processing('fail');
    }).done(function() {
      show_processing('done');
      return post_ajax_processing();    // ajax 送信後の処理
    });
  });
});

// -----------------------------------------------------------
//  表「タイトル」or「並び替え」のクリック検出と ajax 通信
// -----------------------------------------------------------
$(function() {
  $(document).on('click', '.th-column', function() {
    var button_val  = this.value;               //  ボタンの value 値
    var data_id     = $(this).data('id');       //  ボタンの id 値
    var sort_by     = $(this).data('sort-by');
    var link_para   = $(this).data('link_para');
    var url_path    = gon.url_path;
    var search_word = gon.search_word;
    var th_column_click_and_sort = gon.th_column_click_and_sort;

    //--- レコード並び順タイプの決定
    if (sort_by === "") {
      sort_by = "desc";
    } else if (sort_by === "desc") {
      sort_by = "asc";
    } else if (sort_by === "asc") {
      sort_by = "desc";
    } else {
      sort_by = "";
    }

    // alert("th_column_click_and_sort = " + th_column_click_and_sort);

    //  表「タイトル」or「並び替え」のクリック検出と ajax 通信の許可／不許可に応じて実行
    if (th_column_click_and_sort === true) {
        processing_show();
        $.ajax({
            url: url_path,
            type: 'GET',
            data: {
                url: url_path,
                data_id: data_id,
                sort_by: sort_by,
                search_word: search_word,
                link_para: link_para,
                crnt_hash: gon.crnt_hash,
                prv_search_word: gon.prv_search_word,
                url_params: gon.url_params
            }
        }).fail(function() {
            return show_processing('fail');
        }).done(function() {
            return show_processing('done');
        });
    }
  });
});

//------------------------------------------------------------
//  ajax 送信後の処理 （Tableau public Viz 表示など）
//------------------------------------------------------------
var post_ajax_processing;
post_ajax_processing  = function() {
  // alert('post_ajax_processing の実行');
  initViz(gon.params);                //  Tableau public Viz 表示
};

//------------------------------------------------------------
//  「検索 クリア」ボタンがクリックされた時 「検索」テキストボックス内の文字をクリアする。
//  注意：「検索」ボックスに文字列が入力された直後に「クリア」がクリックされたときは正しく動作しない。２回めのクリック以降は正常に動作する。
//------------------------------------------------------------
$(function() {
  $(document).on('click', '#search_clear', function() {
    $("#tableau_search").val("");
  });
});
//------------------------------------------------------------
//  「Tableau 検索」input で change エンター入力タイミング
//------------------------------------------------------------
$(function() {
  $(document).on('change', "#tableau_search", function() {
    tableau_search($("#tableau_search").val());
  });
});
//------------------------------------------------------------
//  「Tableau 検索」ボタンがクリックされた時
//------------------------------------------------------------
$(function() {
  $(document).on('click', '#tableau_search_exec', function() {
    tableau_search($("#tableau_search").val());
  });
});
//------------------------------------------------------------
//  「Tableau 検索」処理送信
//------------------------------------------------------------
var tableau_search;
tableau_search  = function(search_val) {
  var url_path  = gon.url_path;
  $.ajax({
    url: url_path,
    type: 'GET',
    data: {
      filter_value: search_val,
      search_word:  search_val
    }
  }).fail(function() {
    return show_processing('fail');
  }).done(function() {
    show_processing('done');
    post_ajax_processing();    // ajax 送信後の処理
  });
};

//------------------------------------------------------------
//  「検索」input で change エンター入力タイミング
//------------------------------------------------------------
$(function() {
  $(document).on('change', "#search", function() {
    search_exec($("#search").val());
  });
});
//------------------------------------------------------------
//  「検索 クリア」ボタンがクリックされた時 「検索」テキストボックス内の文字をクリアする。
//------------------------------------------------------------
$(function() {
  $(document).on('click', '#search_clear', function() {
    $("#search").val("");
  });
});
//------------------------------------------------------------
//  「検索」処理送信  （2020/06/30, 2020/12/28）
//------------------------------------------------------------
var search_exec;
search_exec = function(search_val) {
  var search_url_path = gon.search_url_path;
  var is_full_text  = $("#search").data('is-full-text');
  processing_show();
  $.ajax({
    url:  search_url_path,
    type: 'GET',
    data: {
      search_word: search_val,
      is_full_text: is_full_text,
      url_params: gon.url_params
    }
  }).fail(function() {
    return show_processing('fail');
  }).done(function() {
    return show_processing('done');
  });
};
//------------------------------------------------------------
//  「検索」ボタンがクリックされた時  （2020/06/30）
//------------------------------------------------------------
$(function() {
  $(document).on('click', '#search_exec', function() {
    search_exec($("#search").val());
  });
});

// -----------------------------------------------------------
//  ページネーション（改ページ制御）のクリック検出と ajax 通信  （2020/05/07）
// -----------------------------------------------------------
$(document).on('click', '.li-pagination', function() {
  var button_val    = $(this).data('value');    //  ボタンの value 値
  var data_id       = gon.data_id;              //  ボタンの id 値
  var sort_by       = gon.sort_by;
  var link_para     = $(this).data('link_para');
  var url_path      = gon.url_path;
  var search_word   = gon.search_word ;
  var is_full_text  = $(this).data('is-full-text');
  var rec_count     = $(this).data('rec-count');

  //--- レコード並び順タイプの決定
  //ここ「ページネーション」では、決定しない。

  processing_show();
  $.ajax({
    url: url_path,
    type: 'GET',
    data: {
      url: url_path,
      data_id: data_id,
      page_id: button_val,
      sort_by: sort_by,
      is_full_text: is_full_text,
      rec_count: rec_count,
      search_word: search_word,
      link_para: link_para,
      prv_search_word: gon.prv_search_word,
      url_params: gon.url_params
    }
  }).fail(function() {
    return show_processing('fail');
  }).done(function() {
    show_processing('done');
    return post_ajax_processing();    // ajax 送信後の処理
  });
  return false;   //  勝手にページトップに返らないようにする。(ver1.02)
});
// -----------------------------------------------------------
//  表「データ項目(cell_item)」のクリック検出と ajax 通信
// -----------------------------------------------------------
$(function() {
  $(document).on('click', '.cell_item', function() {
    var button_val      = this.value;               //  ボタンの value 値
    var data_id         = $(this).data('id');       //  ボタンの id 値
    var sort_by         = $(this).data('sort-by');
    var link_para       = $(this).data('link_para');
    var url_path    =   gon.url_path;

    processing_show();
    $.ajax({
      url: url_path,
      type: 'GET',
      data: {
        url: url_path,
        data_id: data_id,
        sort_by: sort_by,
        link_para: link_para,
        url_params: gon.url_params
      }
    }).fail(function() {
      return show_processing('fail');
    }).done(function() {
      return show_processing('done');
    });
  });
});









